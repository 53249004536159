import {ExperimentsBag} from '@wix/wix-experiments'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const getExperiments = (state: CommonState) => state.experiments

export const isExperimentNameEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.ExperimentName')

export const isRecurringEventsEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.RecurringEvents')

export const isGroupsEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.GroupsUnreg')

export const isNewMobileCheckoutEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.NewMobileCheckout')
